<template>
    <div class="agreement-content">
        <!-- eslint-disable  -->
        <p dir="ltr"><strong>1. ОБЩИЕ ПОЛОЖЕНИЯ&nbsp;</strong></p>

        <p dir="ltr"><strong>Настоящее Соглашение является публичной офертой и определяет условия использования материалов и сервисов, размещенных на сайте, а также на страницах в социальных сетях Продавца в сети Интернет посетителями и пользователями данных Сервисов.&nbsp;</strong></p>

        <p dir="ltr"><strong>1. Термины, используемые в настоящем Соглашении:&nbsp;</strong></p>

        <p dir="ltr"><strong>1.1. Покупатель (Пользователь) &mdash; пользователь сети Интернет, принявший условия настоящего Соглашения, осуществивший оплату на cайте Продавца.&nbsp;</strong></p>

        <p dir="ltr"><strong>1.2. Администрация Сервисов (Продавец) — Elenashop LLC, регистрационный номер EIN 82-5308173. Юридический адрес организации 432, PACIFIC CT, WHEELING, IL 60090-5154, USA.</strong></p>

        <p dir="ltr"><strong>1.3. Произведения (Контент) &mdash; тексты либо аудио-видео-записи, разработанные Продавцом (включая медитации, вебинары, пр.), представленные в сети Интернет в различных форматах, размещенные на сайте Продавца, доступные Пользователям после оплаты.&nbsp;</strong></p>

        <p dir="ltr"><strong>1.4. Заказ &mdash; заявка Пользователя на приобретение полного доступа к Произведению Продавца, оформленная посредством электронной формы на сайте самим Пользователем. Подтверждением факта оформления Заказа является оплата товара на сайте. При оформлении Заказа Пользователь указывает информацию о карте Пользователя. Продавцу доступна не вся информация о карте Пользователя, а лишь та, которую предоставляет платежная система, чтобы идентифицировать Пользователя;</strong></p>

        <p dir="ltr"><strong>1.5. Сайт Продавца (Сайт) &mdash; информационный ресурс в сети Интернет, принадлежащий Продавцу, расположенный на домене https://elena.shop/</strong></p>

        <p dir="ltr"><strong>1.6. Сайт Продавца и его страницы в социальных сетях (далее совместно - Сервисы) &ndash; информационные ресурсы, принадлежащие Продавцу.&nbsp;</strong></p>

        <p dir="ltr"><strong>1.7. Соглашение &mdash; настоящее Пользовательское Соглашение, регулирующее отношения Продавца и Пользователя на протяжении всего периода взаимодействия по поводу приобретения товаров и услуг, в том числе получения доступа Пользователя к Контенту и Сервисам.&nbsp;</strong></p>

        <p dir="ltr"><strong>1.8. Медитация &ndash; аудио-запись, которая размещается на сайте Продавца в электронном формате.</strong></p>

        <p dir="ltr"><strong>1.9. Вебинар &ndash; видео-запись, которая размещается на сайте Продавца в электронном формате.</strong></p>

        <p>&nbsp;</p>

        <p dir="ltr"><strong>2. ПРЕДМЕТ СОГЛАШЕНИЯ&nbsp;</strong></p>

        <p dir="ltr"><strong>2.1. Продавец на платной основе предоставляет Покупателю полный доступ к прослушиванию медитации и просмотру вебинара на сайте. Покупатель обязуется неукоснительно соблюдать условия, изложенные в настоящем Соглашении.&nbsp;</strong></p>

        <p dir="ltr"><strong>2.2. Нажатием на кнопку на странице оплаты, Покупатель подтверждает, что принимает условия настоящего Соглашения.&nbsp;</strong></p>

        <p>&nbsp;</p>

        <p dir="ltr"><strong>3. ОБЯЗАННОСТИ СТОРОН&nbsp;</strong></p>

        <p dir="ltr"><strong>3.1. Обязанности Продавца:&nbsp;</strong></p>

        <p dir="ltr"><strong>3.1.1. Обеспечить доступ Покупателя к Контенту и Сервисам на условиях, указанных в настоящем Соглашении.&nbsp;</strong></p>

        <p dir="ltr"><strong>3.1.2. Не разглашать третьим лицам сведения, полученные от Покупателя при покупке.&nbsp;</strong></p>

        <p dir="ltr"><strong>3.1.3. Осуществлять разностороннюю защиту Личного кабинета Пользователя на сайте;&nbsp;</strong></p>

        <p dir="ltr"><strong>3.1.4. Предоставить всю доступную информацию о Пользователе уполномоченным на то органам государственной власти в случаях, установленных законом США.&nbsp;</strong></p>

        <p dir="ltr"><strong>3.2. Обязанности Покупателя:&nbsp;</strong></p>

        <p dir="ltr"><strong>3.2.1. Использовать Произведения исключительно в личных некоммерческих целях. В частности: для чтения, прослушивания, просмотра, а также иных семейных и домашних нужд, не связанных с осуществлением предпринимательской деятельности.&nbsp;</strong></p>

        <p dir="ltr"><strong>3.2.2. Покупатель соглашается:&nbsp;</strong></p>

        <p dir="ltr"><strong>&mdash; не передавать в пользование свою учетную запись и/или логин и пароль своего Личного кабинета третьим лицам;&nbsp;</strong></p>

        <p dir="ltr"><strong>&mdash; не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сервисами.&nbsp;</strong></p>

        <p dir="ltr"><strong>&mdash; не нарушать каким - либо образом работоспособность Сервисов.&nbsp;</strong></p>

        <p dir="ltr"><strong>3.2.3. Покупатель не имеет права:&nbsp;</strong></p>

        <p dir="ltr"><strong>&mdash; передавать либо рассылать приобретенные Произведения третьим лицам как полностью, так и частично;&nbsp;</strong></p>

        <p dir="ltr"><strong>&mdash; распространять Произведения и его элементы посредством использования средств массовой коммуникации, в том числе, но не исключительно, радио, телевидение, интернет;&nbsp;</strong></p>

        <p dir="ltr"><strong>&mdash; осуществлять изготовление (тиражирование) Произведений или их элементов в любой форме;</strong></p>

        <p dir="ltr"><strong>&mdash; изменять или прочим образом перерабатывать тексты Произведений. Покупатель предупрежден о том, что нарушение положений Соглашения является нарушением законодательства об авторском праве и влечет наложение ответственности, предусмотренной действующим законодательством США, а также нормами международного права.&nbsp;</strong></p>

        <p dir="ltr"><strong>3.2.6. Использование Контента и материалов Продавца, без согласия правообладателей не допускается. Вся информация, размещаемая в указанных выше Сервисах, является собственностью Продавца и его контрагентов, охраняется законодательством США.&nbsp;</strong></p>

        <p>&nbsp;</p>

        <p dir="ltr"><strong>4. УСЛОВИЯ ПРИОБРЕТЕНИЯ ПРОИЗВЕДЕНИЯ&nbsp;</strong></p>

        <p dir="ltr"><strong>4.1. Для совершения покупки требуется заполнить все поля формы Заказа на Сервисах Продавца.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.2. В случае изменения каких-либо данных, предоставленных ранее Покупателем и необходимых для надлежащего исполнения Продавцом обязательств по договору, Покупатель обязуется незамедлительно уведомить об этом Продавца, направив соответствующее сообщение в Службу клиентской поддержки в директ Инстаграм аккаунта @elena.siaglo.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.3. Продавец не несет ответственности за неисполнение и/или ненадлежащее исполнение обязательств по продаже и/или доставке Покупателю услуг, если указанное нарушение связано с предоставлением недостоверных и/или недействительных данных о Покупателе, а равно невыполнением Покупателем условий настоящего Соглашения.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.4. Цена Произведений может изменяться Продавцом на Сервисах круглосуточно без предварительного уведомления об этом, и указываются в индивидуальном статусе и подробной карточке Произведения, отображаемым в каталоге на Сервисах.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.5. Продавец принимает оплату за Произведения в валюте Российский рубль.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.6. За дополнительной информацией касательно приобретения доступа к Произведениям надлежит обращаться в Службу клиентской поддержки в директ Инстаграм аккаунта @elena.siaglo.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.7. Условия оплаты доступа к Произведениям физическими лицами:&nbsp;</strong></p>

        <p dir="ltr"><strong>4.7.1. Физическое лицо может приобрести Произведение на Сервисах, оплатив его следующими способами:&nbsp;</strong></p>

        <p dir="ltr"><strong>&mdash; банковской картой,&nbsp;</strong></p>

        <p dir="ltr"><strong>&mdash; электронными деньгами.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.7.2. Оплата Произведения банковской картой. Оплата Произведений может осуществляться с использованием следующих дебетовых и кредитных карт: Visa, Visa Electron, MasterCard, Maestro, МИР.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.7.3. Оплата Произведения электронными деньгами. Оплата Произведений может осуществляться с использованием платёжной системы Яндекс.Деньги. Подробная информация о порядке и правилах осуществления платежей предоставляется соответствующими платежными системами.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.7.4 В случае оплаты электронными деньгами и/или банковской картой подтверждение Заказа осуществляется после поступления денежных средств на расчетный счет Продавца.&nbsp;</strong></p>

        <p dir="ltr"><strong>4.7.5. Отмена Заказа и возврат денег осуществляются в порядке и форме, предусмотренными разделом 5 настоящего Соглашения.&nbsp;</strong></p>

        <p>&nbsp;</p>

        <p dir="ltr"><strong>5.ВОЗВРАТ И ОБМЕН ПРОИЗВЕДЕНИЙ&nbsp;</strong></p>

        <p dir="ltr"><strong>5.1. Общие положения&nbsp;</strong></p>

        <p dir="ltr"><strong>5.1.1. Возврат денежных средств осуществляется исключительно путем обращения в Службу клиентской поддержки в директ Инстаграм аккаунта @elena.siaglo.</strong></p>

        <p dir="ltr"><strong>5.1.2 В течение 48 часов после оплаты Покупатель может отказаться от пользования курсом и запросить возврат денег. В ином случае, если прошло более 48 часов после оплаты, возврат денежных средств не производится. Для возврата Покупатель должен написать менеджеру свой email и 4 последние цифры карты с которой производилась оплата.</strong></p>

        <p dir="ltr"><strong>5.1.3. Рассмотрение заявления о возврате/обмене Произведения производится Продавцом в течение 7 календарных дней с момента его получения.&nbsp;</strong></p>

        <p dir="ltr"><strong>5.1.4. Возврат денежных средств осуществляется путем безналичного перечисления денежных средств по банковским реквизитам Покупателя, указанным в Заказе.&nbsp;</strong></p>

        <p>&nbsp;</p>

        <p dir="ltr"><strong>6. ОТВЕТСТВЕННОСТЬ СТОРОН&nbsp;</strong></p>

        <p dir="ltr"><strong>6.1. Покупатель принимает на себя полную ответственность и риски, связанные с использованием Произведения.&nbsp;</strong></p>

        <p dir="ltr"><strong>6.2. Продавец не несет ответственности за любые расходы Покупателя или прямой либо косвенный ущерб, который может быть нанесен Покупателю вследствие использования Произведения, не в соответствии с назначением.&nbsp;</strong></p>

        <p dir="ltr"><strong>6.3. Продавец не несет отвeтствeнности за качество доступа к Произведению через сеть Интернет.&nbsp;</strong></p>

        <p dir="ltr"><strong>6.4. Продавец не несет ответственности за прямой или косвенный ущерб, понесенный Покупателем в результате ошибок передачи данных, сбоев/дефектов в работе программного обеспечения и/или оборудования, потерь и повреждений данных, ошибок обработки или отображения данных, задержек в передаче данных и других сбоев, случившихся не по вине Продавца.&nbsp;</strong></p>

        <p dir="ltr"><strong>6.5. Сервисы Продавца предоставляются без каких-либо прямых или косвенных гарантий того, что указанные Сервисы могут подходить или не подходить для конкретных целей использования Покупателем. Изложенное означает, что Продавец отвечает за качество Произведений, но не за результаты их использования Покупателем.&nbsp;</strong></p>

        <p dir="ltr"><strong>6.6. Продавец не несет ответственности за невозможность пользования Сервисами Покупателем по каким-либо причинам, включая, но не ограничиваясь: отсутствие доступа в Интернет, ошибки, упущения, прерывания, удаление, дефекты, задержки в обработке или передаче данных, нарушения работы линий связи, неисправности оборудования, любые технические сбои или иные проблемы любых телефонных сетей или служб, компьютерных систем, серверов или провайдеров, компьютерного или телефонного оборудования, программного обеспечения, невыполнение обязательств поставщиков тех или иных услуг, кражу, уничтожение или неправомерный доступ к материалам Пользователей, размещенным на Сервисах или в любом другом месте, если такие причины имели место не по вине Продавца.&nbsp;</strong></p>

        <p dir="ltr"><strong>6.7. Продавец не несет ответственности за любые расходы Пользователя или прямой либо косвенный ущерб, включая упущенную выгоду или потерянные данные, вред чести, достоинству или деловой репутации, который может быть причинен Пользователю вследствие использования Сервисов и (или) сопутствующих Приложений, в целях, отличных от указанных в настоящем Соглашении.&nbsp;</strong></p>

        <p dir="ltr"><strong>6.8. Продавец не несет ответственности за посещение и использование внешних ресурсов, ссылки на которые могут содержаться на Сервисах.&nbsp;</strong></p>

        <p dir="ltr"><strong>6.9. Администрация Сервисов не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками, связанными с содержанием Сервисов, товарами или услугами, доступными или полученными через внешние сайты или ресурсы либо иные контакты Пользователя, в которые он вступил, используя размещенную на Сервисах информацию или ссылки на внешние ресурсы.&nbsp;</strong></p>

        <p dir="ltr"><strong>6.10. Покупатель согласен с тем, что Администрация Сервисов не несет какой-либо ответственности и не имеет каких-либо обязательств, в связи с рекламой, которая может быть размещена на Сервисах.&nbsp;</strong></p>

        <p>&nbsp;</p>

        <p dir="ltr"><strong>8. ПРЕДЕЛЫ ДЕЙСТВИЯ СОГЛАШЕНИЯ&nbsp;</strong></p>

        <p dir="ltr"><strong>8.1. Соглашение вступает в силу с момента выражения Покупателем согласия путем нажатия кнопки &ldquo;Продолжить&rdquo; на странице оплаты Продавца.&nbsp;</strong></p>

        <p dir="ltr"><strong>8.2. Стороны освобождаются от ответственности за неисполнение обязательств по настоящему Соглашению в случае, если такое неисполнение явилось прямым следствием обстоятельств непреодолимой силы, возникших после присоединения к Соглашению и если эти обстоятельства Стороны не могли ни предвидеть, ни предотвратить разумными мерами.&nbsp;</strong></p>

        <p>&nbsp;</p>

        <p dir="ltr"><strong>9. ПРОЧИЕ УСЛОВИЯ&nbsp;</strong></p>

        <p dir="ltr"><strong>9.1. В случае, если какое-либо положение или какая-либо часть положения Соглашения признаны недействительными или не имеющими юридической силы, остальные положения Соглашения сохраняют юридическую силу.&nbsp;</strong></p>

        <p dir="ltr"><strong>9.2. Продавец вправе вносить изменения в Соглашение с обязательным размещением соответствующей информации на Сервисах Продавца.&nbsp;</strong></p>

        <p dir="ltr"><strong>9.3. По всем возникающим вопросам Покупатель вправе обращаться в Службу клиентской поддержки в директ Инстаграм аккаунта @elena.siaglo. Продавец обязуется в разумный срок предпринять необходимые и возможные меры по рассмотрению и разрешению таких обращений.&nbsp;</strong></p>

        <p dir="ltr"><strong>9.4. Продавец вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу с момента размещения новой версии Соглашения на Сервисах. При несогласии Покупателя с внесенными изменениями он обязан отказаться от доступа к Контенту и Сервисам, прекратить использование Произведений.&nbsp;</strong></p>

        <p dir="ltr"><strong>9.7. Регистрируясь на Сервисах, Покупатель соглашается с получением сообщений после покупки &mdash; о совершенной оплате и о составе заказа.&nbsp;</strong></p>

        <p>&nbsp;</p>

        <p dir="ltr"><strong>10. РАЗРЕШЕНИЕ СПОРОВ&nbsp;</strong></p>

        <p dir="ltr"><strong>10.1. Споры и разногласия, которые могут возникнуть по данному Соглашению, решаются путем соблюдения досудебного (претензионного) порядка. Срок рассмотрения претензии &mdash; 1 (один) календарный месяц с момента ее получения.&nbsp;</strong></p>

        <p dir="ltr"><strong>10.2. Если Стороны не придут к соглашению, то их разногласия разрешаются в судебном порядке согласно действующему законодательству США.</strong></p>
        <!-- eslint-enable -->
    </div>
</template>

<script>

export default {
  name: 'AgreementPage',
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    getStripeKey() {
      return 'pk_test_EbFCyRpagCLakgHBurKM1Hkk';
    },
  },
  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.agreement-content {
    margin: 30px 0;
    font-size: 14px;
    line-height: 20px;
    padding: 0 24px;
    background-size: cover;
    min-height: calc(100vh - 240px);

    p {
        margin: 15px 0;
    }
}
</style>
