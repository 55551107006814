<template>
  <div class="agreement-page">
    <BaseSecondaryPagesHeader title="Пользовательское соглашение"></BaseSecondaryPagesHeader>
    <Agreement></Agreement>
  </div>
</template>

<script>
import BaseSecondaryPagesHeader from '@/components/header/BaseSecondaryPagesHeader.vue';
import Agreement from '@/components/secondary-pages/Agreement.vue';

export default {
  name: 'Home',
  components: {
    BaseSecondaryPagesHeader, Agreement,
  },
  mounted() {
    document.documentElement.style.setProperty('--main-bg-color', '#f6f7fb');

    console.log(this.$route);
  },
  beforeDestroy() {
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
  },
};
</script>
